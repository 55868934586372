import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, Grid, IconButton, Stack, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import {QuestionBar} from "./Questions/QuestionDialogContent";


export const PinnedQuestions = ({questions}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/' + id + '/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return (
        <Box>
            {selected !== '' &&
                <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'lg'} fullWidth={true}>
                    <DialogContent sx={{borderRadius: '2rem', border:'2px solid '+'rgba(255,255,255,0.35)',}}
                                   variant={elementPlaying.tipo === 'quiz' || elementPlaying.tipo === 'poll' ? 'quizPoll' : 'inherit' }>
                        <Box sx={{textAlign: 'right'}}>
                            <IconButton size={'large'} onClick={() => setSelected('')}>
                                <Close/>
                            </IconButton>
                        </Box>
                        <Stack alignItems={'center'} spacing={3}>
                            <img style={{
                                width: '150px',
                                filter: 'brightness(0) invert(1)'
                            }} src={'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>
                            <Typography sx={{px: 0}} textAlign={'center'} variant={'h5'}
                                        fontWeight={'bold'}
                                        color={elementPlaying.tipo === 'quiz' || elementPlaying.tipo === 'poll' ? '#181731' : 'inherit'}>
                                {elementPlaying.domanda}
                            </Typography>
                        </Stack>
                        <br/>
                        <QuestionSwitch elementPlaying={elementPlaying}/>
                    </DialogContent>
                </Dialog>}
            <Stack spacing={3} mx={3.5}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) =>
                        <Button key={i} variant={'home-question'}
                                //startIcon={questionType[q.data().tipo].icon}
                                startIcon={q.data()?.tipo ?
                                    <img src={require(`../assets/images/questionsType/${q.data()?.tipo}.png`)} height={'65px'}/>
                                    : questionType[q.data().tipo].icon}
                                onClick={() => setSelected(q.id)}
                        >
                            {questionType[q.data().tipo]?.homeLabel || q.data().tipo}
                        </Button>
                    )
                }
            </Stack>
            {/*<Stack spacing={3} mx={5}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) =>
                        <Stack key={i} direction={'row'} variant={'home-question'}
                                //startIcon={questionType[q.data().tipo].icon}
                                onClick={() => setSelected(q.id)}
                        >
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={6}>
                                    <img src={`/questionsType/${q.data().tipo}.png`} height={'65px'}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign={'left'}>
                                        {questionType[q.data().tipo]?.homeLabel || q.data().tipo}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    )
                }
            </Stack>*/}
        </Box>
    )
}