import {emojisplosion} from "emojisplosion";
import {Box, Button, Stack} from "@mui/material";
import handUp from "../../../assets/images/hand-up.png";
import React, {useEffect, useMemo} from "react";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "../../shared/SuccessCheck";
import {Clear, Remove} from "@mui/icons-material";

const ReserveSpot = ({answer, sendAnswer, success, answered}) => {
    console.log("answer:",answer)
    console.log("success:",success)
    console.log("answered:",answered)

    const reserved = useMemo(() => {
        if(!answered)
            return false
        return answer === 'reserved'
    }, [answer])


    console.log("reserved:;",reserved)

    let progress = useMotionValue(90)

    const handleReserveSpot = async (e) => {
        emojisplosion({
            emojiCount: 5,
            emojis: ['🙌'],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        sendAnswer(answer === 'reserved' ? 'not-reserved' : 'reserved')
    }

    return (
        <Stack>
            {(reserved) &&
                <Box style={{width:'20%', margin: 'auto'}}>
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: 100 }}
                        style={{ x: progress }}
                        transition={{ duration: 0.8 }}
                    />
                    <SuccessCheck progress={progress}/>
                </Box>}
            <br/>
            <Button id='clapButton' onClick={handleReserveSpot} fullWidth
                    disabled={reserved}
                    //variant={'outlined'}
                    startIcon={<img style={{width:'90%', opacity: (reserved) ? 0.5 : 1}} src={handUp} />}>
            </Button>
            <br/>
            {(reserved) &&
                <Button onClick={handleReserveSpot} fullWidth
                        startIcon={<Clear/>}>
                    Abbassa la mano
                </Button>}
        </Stack>
    )
}

export default ReserveSpot