import {
    QuestionAnswer,
    InsertEmoticon,
    Quiz,
    Poll,
    TextFields,
    Cloud,
    Flaky,
    TouchApp,
    Photo, QuestionMark, Mic, PanTool
} from "@mui/icons-material";

export const questionType = {
    qna: {
        text: 'Q&A',
        icon: <QuestionAnswer/>,
        homeLabel: 'Q&A'
    },
    toolpress: {
        text: 'Toolpress',
        icon: <QuestionAnswer/>,
        homeLabel: 'Q&A'
    },
    quiz: {
        text: 'Quiz',
        icon: <Quiz/>,
        homeLabel: 'Quiz!'
    },
    poll: {
        text: 'Sondaggio',
        icon: <Poll/>,
        homeLabel: 'Sondaggio'
    },
    wordcloud: {
        text: 'Wordcloud',
        icon: <Cloud/>,
        homeLabel: 'Wordcloud'
    },
    sentiment: {
        text: 'Sentiment',
        icon: <InsertEmoticon/>,
        homeLabel: 'Sentiment'
    },
    siNo: {
        text: 'Sí/No',
        icon: <Flaky/>,
        homeLabel: 'Sí o No ?'
    },
    clickTo:{
        text: 'Click To',
        icon: <TouchApp/>,
        homeLabel: 'Click To'
    },
    sendPhoto:{
        text:"Invio foto",
        icon:<Photo/>,
        homeLabel: 'Selfie!'
    },
    audio:{
        text:"Invio audio",
        icon:<Mic/>,
        homeLabel: 'Audio'
    },
    reserveSpot: {
        text: "Prenota il tuo posto!",
        icon: <PanTool/>,
        homeLabel: 'Prenota!'
    },
    altro:{
        text:"Altro",
        icon:<QuestionMark/>,
        homeLabel: '?'
    },
}

export default questionType